<template>
  <div>
    <vs-row class="flex justify-between">
      <!-- left side -->
      <div class="sm:w-1/2">
        <!-- Displays name/email/phone accordingly to whichable is available first, if non of these exists, Customer details not available is displayed -->
        <div class="flex flex-wrap items-center justify-between wrap-span">
          <h3 class="font-normal mid-blue mb-2" v-if="paymentRequestDetail.payeeName">{{ paymentRequestDetail.payeeName }}</h3>
          <h3 class="font-normal mid-blue mb-2" v-if="paymentRequestDetail.payeeEmail && !paymentRequestDetail.payeeName">
            <a :href="`mailto:${paymentRequestDetail.payeeEmail}`">{{paymentRequestDetail.payeeEmail}}</a>
          </h3>
          <h3 class="font-normal mid-blue mb-2" v-if="paymentRequestDetail.payeePhone && !paymentRequestDetail.payeeName && !paymentRequestDetail.payeeEmail">{{ paymentRequestDetail.payeePhone }}</h3>
          <h3 class="font-normal mb-2" v-if=" !paymentRequestDetail.payeePhone && !paymentRequestDetail.payeeName && !paymentRequestDetail.payeeEmail && productConfigType == 'THREE_PARTY'">Customer details not available</h3>
        </div>
        <!--  -->
        <div class="flex flex-wrap items-center wrap-span mb-2">
          <p class="w-24 m-0 mr-4 text-base">Request ID:</p>
          <a class="m-0 lg:w-1/2 md:w-auto sm:w-auto text-base" v-if="['Pending'].includes(paymentRequestDetail.paymentStatus) && !isTwoPartyProduct" @click="gotoPaymentPage">
            {{ paymentRequestDetail.paymentRequestId }}
          </a>
          <span v-else class="m-0 lg:w-1/2 md:w-auto sm:w-auto text-base">{{ paymentRequestDetail.paymentRequestId }}</span>
        </div>
        <div class="flex flex-wrap items-center wrap-span mb-2">
          <p class="w-24 m-0 mr-4 text-base">Reference:</p>
          <span class="m-0 lg:w-1/2 md:w-auto sm:w-auto text-base">{{ paymentRequestDetail.reference }}</span>
        </div>
        <div class="flex flex-wrap items-center wrap-span mb-2">
          <p class="w-24 m-0 mr-4 text-base">Created by:</p>
          <span class="m-0 lg:w-1/2 md:w-auto sm:w-auto text-base">{{ paymentRequestDetail.createdBy }}</span>
        </div>
        <div class="flex flex-wrap items-center wrap-span mb-2">
          <!-- for recurring type of payments -->
          <p class="w-24 m-0 text-base" v-if="isRecurring">Recurring</p>
          <!-- for recurring, active state, clickable, popups billing stuff-->
          <span v-else-if="isRecurring && paymentRequestDetail.paymentStatus === 'Active'" class="lg:w-1/2 md:w-auto sm:w-auto mb-0 ml-4 text-base">
            <u class="link">billed {{ paymentRequestDetail.frequency.interval }} times every {{ paymentRequestDetail.frequency.every }}</u>
          </span>

          <!-- plans pop up -->
          <payment-plans-pop-up
            v-if="payLaterPopUp"
            title="Payment schedule"
            :isOpen.sync="payLaterPopUp"
            :calculations="calculations"
            :paymentRequest="paymentRequestDetail"
            :onClose="closePayLaterPopUp"
          />
        </div>
        <div class="mt-4">
          <span :class="`payment-status mr-5 font-normal inline-block ${conditionalClass(paymentRequestDetail.paymentStatus)}`">{{ paymentRequestDetail.paymentStatus }}</span>
          <span class="text-base block md:inline-block pt-2 md:pt-0">{{ ["Pending"].includes(paymentRequestDetail.paymentStatus) ? `(expires ${momentExpiryDate(paymentRequestDetail.expiredDate)})` : "" }}</span>
        </div>
        <br />
        <br />
        <div class="button-combo flex-wrap" v-if="!paymentRequestDetail.franchisorId || viewingByFranchisor">
          <vs-button
            v-if="showEditOption"
            flat
            v-round
            size="medium"
            class="only-border-btn mr-3 mb-3"
            @click="handleEditRequest"
          >
            Edit request
          </vs-button>
          <vs-button
            v-if="showCancelButton"
            flat
            v-round
            size="medium"
            class="only-border-btn mr-3 mb-3"
            :disabled="paymentRequestDetail.paymentStatus === 'Expired'"
            @click="openDeletePopUp"
          >
            {{ (isRepayEnabled && isAutoDebit) ? "Repay balance" : "Cancel request" }}
          </vs-button>

          <vs-button
            v-if="['Expired', 'Pending'].includes(paymentRequestDetail.paymentStatus) && !isTwoPartyProduct && (paymentRequestDetail.payeeEmail || paymentRequestDetail.payeePhone)"
            flat
            v-round size="medium"
            class="mr-3 mb-3"
            @click="openResendPopUp"
          >
            <span>Resend request</span>
          </vs-button>

          <vs-button v-if="showMarkAsPaid()" flat v-round size="medium" class="mr-3 mb-3" @click="statusPopUp = true">
            <span>Mark as paid</span>
          </vs-button>
          <vs-button v-if="showTopUpButton" flat v-round size="medium" class="mr-3 mb-3" @click="handleRequestFunding">
            <span>Request additional funding</span>
          </vs-button>

          <vs-button v-if="isDisbursed" flat v-round size="medium" class="mr-3 mb-3" @click="sendRemittance">
            <span>Send remittance</span>
          </vs-button>

          <vs-button v-if="showDisbursementButton" flat v-round size="medium" class="mr-3 mb-3" @click="handleDisbursementDate">
            <span>Edit disbursement date</span>
          </vs-button>
        </div>
      </div>

      <!-- right side -->
      <div class="sm:w-1/2 flex grid flex-col">
        <div class="lg:w-4/5 w-full justify-self-end">
          <div class="flex flex-col wrap-span" v-if="isOneOff && (hasPayNow || hasPaylater)">
            <div class="flex flex-wrap items-center justify-between wrap-span mb-2 lg:mt-0 mt-10">
              <h3 class="lg:text-xl md:text-xl text-lg text-black m-0 font-normal">Request amount</h3>
              <h3 class="font-normal mid-blue">
                {{ paymentRequestDetail.paymentSummary.requestAmount }}
              </h3>
            </div>
            <div class="flex mb-2"
              v-if="isOneOff && hasPayNow &&
                paymentRequestDetail.paymentStatus === 'Completed' && !paymentRequestDetail.manualPaymentByAdmin && (!paymentRequestDetail.selectedPaymentPlan || (paymentRequestDetail.selectedPaymentPlan && Object.keys(paymentRequestDetail.selectedPaymentPlan).length == 0))"
              >
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Fees</p>
              <h3 class="lg:w-1/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                {{ paymentRequestDetail.paymentSummary.fees }}
              </h3>
            </div>
            <div class="flex flex-wrap items-center justify-between wrap-span mb-2" v-if="paymentRequestDetail.paymentSummary && paymentRequestDetail.paymentSummary.topUp">
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Additional funding requests</p>
              <span class="font-normal lg:w-1/ md:w-auto w-max text-right text-base">
                {{ paymentRequestDetail.paymentSummary.topUp }}
              </span>
            </div>

            <div class="flex mb-2"
              v-if="isOneOff && hasPayNow &&
                paymentRequestDetail.paymentStatus === 'Completed' && !paymentRequestDetail.manualPaymentByAdmin && (!paymentRequestDetail.selectedPaymentPlan || (paymentRequestDetail.selectedPaymentPlan && Object.keys(paymentRequestDetail.selectedPaymentPlan).length == 0))
              "
              >
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Amount payable by borrower</p>
              <h3 class="lg:w-1/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                <template v-if="paymentRequestDetail.payNowUsedCard">{{ paymentRequestDetail.paymentSummary.payableByCustomer }}</template>
              </h3>
            </div>

            <div class="flex mb-2"
              v-if="isOneOff && hasPayNow &&
                paymentRequestDetail.paymentStatus === 'Completed' && !paymentRequestDetail.manualPaymentByAdmin && (!paymentRequestDetail.selectedPaymentPlan || (paymentRequestDetail.selectedPaymentPlan && Object.keys(paymentRequestDetail.selectedPaymentPlan).length == 0))
              "
              >
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Payable to nominated account</p>
              <h3 class="lg:w-1/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                <template v-if="paymentRequestDetail.payNowUsedCard">
                  {{ paymentRequestDetail.paymentSummary.payableToYou }}
                </template>
              </h3>
            </div>
          </div>

          <div class="flex flex-col wrap-span" v-else-if="isRecurring">
            <div v-if="showingFeesStatus.includes(paymentRequestDetail.paymentStatus)" class="font-normal text-black text-2xl">Payment Detail</div>
            <div v-if="showingFeesStatus.includes(paymentRequestDetail.paymentStatus)" class="flex">
              <p class="mb-0 lg:w-2/3 md:w-auto w-max lg:text-lg md:text-base text-sm">Recurring amount</p>
              <h3 class="font-normal lg:w-1/3 md:w-auto w:max text-right mid-blue">{{ moneyFormat(paymentRequestDetail.amount.$numberDecimal) }}</h3>
            </div>

            <div class="flex mb-2" v-if="paymentRequestDetail.paymentStatus === 'Active'">
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Fees</p>
              <h3 class="lg:w-1/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                {{ paymentRequestDetail.paymentStatus === "Active" ? moneyFormat(paymentRequestDetail.transactions[0].surcharge) : 0}}
              </h3>
            </div>

            <div class="flex mb-2" v-if="paymentRequestDetail.paymentStatus === 'Active'">
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Total recurring amount</p>
              <h3 class="lg:w-1/4 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                {{ paymentRequestDetail.paymentStatus === "Active" ? moneyFormat(paymentRequestDetail.transactions[0].totalAmount) : moneyFormat(paymentRequestDetail.amount.$numberDecimal) }}
              </h3>
            </div>
          </div>
          <!-- Pay later -->
          <template v-if="!viewingByFranchisor">
            <div class="flex flex-wrap items-center justify-between wrap-span mb-2" v-if="isOneOff && hasPaylater && showFeeDiv() && (showingFeesStatus.includes(paymentRequestDetail.paymentStatus) || isTwoPartyProduct)">
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">
                Service fee <span v-if="!paymentRequestDetail.franchisorId && !isTwoPartyProduct">({{ selectedPlan && selectedPlan.feeModel == "MSF_INCLUDED"? "paid by merchant" : "paid by borrower" }})</span>
              </p>
              <span class="font-normal lg:w-1/ md:w-auto w-max text-right text-base">
                {{ paymentRequestDetail.paymentSummary.fees }}
              </span>
            </div>

            <div class="flex flex-wrap items-center justify-between wrap-span mb-2" v-if="isOneOff && hasPaylater && showFeeDiv() && (showingFeesStatus.includes(paymentRequestDetail.paymentStatus) || isTwoPartyProduct)">
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">{{ isTwoPartyProduct ? "Total to repay" : "Total payable by borrower" }}</p>
              <span class="font-normal lg:w-1/3 md:w-auto w-max text-right text-base">
                {{ paymentRequestDetail.paymentSummary.payableByCustomer }}
              </span>
            </div>

            <div class="flex flex-wrap items-center justify-between wrap-span mb-2" v-if="isOneOff && hasPaylater && showFeeDiv() && (showingFeesStatus.includes(paymentRequestDetail.paymentStatus) || isTwoPartyProduct)">
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">{{ paymentRequestDetail.franchisorId ? `Payable to ${paymentRequestDetail.franchisorName}` : "Payable to nominated account" }}</p>
              <span class="font-normal lg:w-1/3 md:w-auto w-max text-right text-base">
                {{ paymentRequestDetail.paymentSummary.payableToYou }}
              </span>
            </div>

            <div class="flex flex-wrap items-center justify-between wrap-span mb-2" v-if="isOneOff && hasPaylater && !['Cancelled', 'Declined', 'Expired', 'Pending'].includes(paymentRequestDetail.paymentStatus) && showCurrentBalance()">
              <p class="lg:text-xl md:text-xl text-lg text-black m-0 font-normal">Current balance</p>
              <h3 class="font-normal mid-blue">{{ moneyFormat(paymentRequestDetail.balances && paymentRequestDetail.balances.balance ? paymentRequestDetail.balances.balance : 0) }}</h3>
            </div>

            <div class="flex flex-wrap items-center justify-between wrap-span mb-2 mt-8" v-if="hasPaylater && ['Active'].includes(paymentRequestDetail.paymentStatus) && paymentRequestDetail.repaymentDate">
              <p class="lg:text-xl md:text-xl text-lg text-black m-0 font-normal">Repayment due on/before:</p>
              <h3 class="font-normal mid-blue">{{ paymentRequestDetail.repaymentDate }}</h3>
            </div>
          </template>
        </div>
      </div>
    </vs-row>

    <!-- pop up after cancel request is clicked -->
    <vs-popup class="holamundo" title="Resend request" :active.sync="resendPopUp">
      <p>Are you sure you want to resend this payment request?</p>
      <div class="flex-end mt-5 flex-end">
        <vs-button @click="resend" class="mr-5" :disabled="resendButtonDisable">{{ resendButtonText }}</vs-button>
        <vs-button @click="hideResendPopup" :disabled="resendButtonDisable">Cancel</vs-button>
      </div>
    </vs-popup>

    <!-- pop up after cancel request is clicked -->
    <vs-popup class="holamundo" :title="isRepayEnabled ? 'Repay balance' : 'Cancel request'" :active.sync="deletePopUp">
      <div v-if="isRepayEnabled && isAutoDebit">
        <p>Are you sure - proceeding will trigger an immediate direct debit on your account for the outstanding balance on this request, which can take 2-3 days to complete (make sure your bank account supports direct debit, otherwise you may incur fees for a dishonoured payment).</p>
        <p class="mt-2">If you wish to pay out the agreement immediately, please EFT the funds to {{ appName }} instead (typically processes within minutes).</p>
      </div>
      <div v-else-if="isRepayEnabled">
        <p>Please contact us via support channels to cancel this request</p>
      </div>
      <div v-else>
        <p>Are you sure you want to cancel this payment request?</p>
      </div>
      <div v-if="isRepayEnabled && isAutoDebit" class="flex mt-4 custom-quillEditor legals-custom payment-legals">
        <div>
          <vs-checkbox class="mt-1" name="repayTerms" id="repayTerms" v-model="repayTerms"></vs-checkbox>
        </div>
        <div>
          <p v-html="latestDirectDebitTerms.explicitTerms"></p>
        </div>
      </div>
      <div class="flex-end mt-5 flex-end" v-if="isRepayEnabled && !isAutoDebit">
        <vs-button @click="hideDeletePopup">Ok</vs-button>
      </div>
      <div class="flex-end mt-5 flex-end" v-else>
        <vs-button @click="cancel" class="mr-5" :disabled="isRepayEnabled && !repayTerms">Yes</vs-button>
        <vs-button @click="hideDeletePopup">Cancel</vs-button>
      </div>
    </vs-popup>

    <vs-popup title="Mark as paid (payment received via other methods)" header-icon="warning" class="close-icon-hidden custom-popup-decline" id="decline-popup" :active.sync="statusPopUp">
      <div class="w-full text-justify">
        <p class="flex mb-2">This action will change the payment request status to complete, and create a transaction for display purposes only indicating that funds have been disbursed to the nominated merchant account - are you sure you want to proceed?</p>
      </div>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" @click="updateStatus" action-btn :disabled="statusButtonDisable">Continue</vs-button>
        <vs-button color="danger" @click="statusPopUp = false" size="large" action-btn class="ml-6 md:ml-10" :disabled="statusButtonDisable">Cancel</vs-button>
      </div>
    </vs-popup>

    <top-up ref="topUpComponent" v-on="$listeners" :product="paymentRequestDetail.selectedPlanConfig" :prId="paymentRequestDetail._id" />
    <edit-disbursement ref="disbursementComponent" v-on="$listeners" :payment-request="paymentRequestDetail" :prId="paymentRequestDetail._id" />
    <payment-request-edit ref="editRequestComponent" v-on="$listeners" :prId="paymentRequestDetail._id" :paymentRequest="paymentRequestDetail" />
  </div>
</template>

<script>
import moment from "moment";
import { Money } from "v-money";
import DatePicker from "vue2-datepicker";
import { mapActions } from "vuex";
import VeeValidate from "vee-validate";
import PaymentPlansPopUp from "./PaymentPlansPopUp.vue";
import TopUp from "./TopUp.vue";
import EditDisbursement from "./EditDisbursement.vue";
import PaymentRequestEdit from "./PaymentRequestEdit.vue";

export default {
  components: {
    DatePicker,
    EditDisbursement,
    Money,
    PaymentPlansPopUp,
    PaymentRequestEdit,
    TopUp
  },

  props: {
    paymentRequestDetail: { type: Object },
    cancelPaymentRequest: { type: Function },
    resendPaymentRequest: { type: Function },
    calculations: { type: Object },
    productConfigType: { type: String },
    updatePaymentRequest: { type: Function },
  },

  data() {
    return {
      appUrl: process.env.VUE_APP_ROOT_API,
      appName: process.env.VUE_APP_NAME == "RelloPay" ? "Rello" : "FlipPay",
      payLaterPopUp: false,
      deletePopUp: false,
      msf: 0,
      totalAmount: 0,
      resendPopUp: false,
      registerBankTransferPopup: false,
      amPm: "",
      counterDanger: "",
      register: {
        amount: "",
        paymentDate: "",
        paymentTime: "",
        transactionReference: "",
        auditNote: "",
      },
      isAdminLogin: false,
      showingFeesStatus: ["Active", "Completed", "Extended", "Submitted"],
      resendButtonDisable: false,
      resendButtonText: "Confirm",
      statusPopUp: false,
      statusButtonDisable: false,
      repayTerms: false,
      latestDirectDebitTerms: {},
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["registerBankTransferPost", "sendRemittanceEmail"]),
    ...mapActions("legals", ["fetchTermsAndConditions"]),

    handleEditRequest() {
      this.$refs.editRequestComponent.handlePrEditPopUp();
    },

    handleRequestFunding() {
      this.$refs.topUpComponent.handleTopUpPopUp();
    },

    handleDisbursementDate() {
      this.$refs.disbursementComponent.handleDisbursementPopUp();
    },

    async sendRemittance() {
      const disbursedTransaction = this.paymentRequestDetail.transactions && this.paymentRequestDetail.transactions.find(item => item.type === "Disbursement" && item.status === "Disbursed");

      if (!disbursedTransaction) {
        this.$vs.notify({
          color: "danger",
          title: "Remittance email",
          text: "Unable to send remittance email. Please try again later.",
        });

        return;
      }

      this.$vs.loading();
      await this.sendRemittanceEmail(disbursedTransaction._id).then(result => {
        this.$vs.notify({
          color: "success",
          title: "Remittance email",
          text: "Remittance email sent successfully.",
        });
      }).catch(ex => {
        this.$vs.notify({
          color: "danger",
          title: "Remittance email",
          text: "Failed to send remittance email. Please try again later.",
        });
      });

      this.$vs.loading.close();
    },

    showMarkAsPaid() {
      let showOption = false;

      if (this.paymentRequestDetail.paymentStatus == "Pending") {
        if (this.paymentRequestDetail.requestOptions.includes("pay-now") && this.paymentRequestDetail.requestOptions.length == 1) {
          showOption = true;
        } else {
          const product = this.paymentRequestDetail.selectedPaymentPlan || this.paymentRequestDetail.payLaterPlan[0];

          if (product && product.currentPlan && product.currentPlan.productConfigType && (product.currentPlan.productConfigType === "THREE_PARTY") && product.currentPlan.isPayNow) {
            showOption = true;
          }
        }
      }

      return showOption;
    },

    gotoPaymentPage() {
      let routeData = this.$router.resolve({
        name: "payments",
        params: {
          id: this.paymentRequestDetail._id,
        },
      });
      window.open(routeData.href, "_blank");
    },

    checkIfAdminLogin() {
      let loggedUser = this.$store.state.AppActiveUser;
      if (loggedUser.adminId && loggedUser.adminId != "" && loggedUser.loginBy === "flip admin") {
        this.isAdminLogin = true;
      }
    },

    showFeeDiv() {
      if (!this.selectedPlan && this.paymentRequestDetail.transactions.length > 0) {
        return true;
      } else if (this.selectedPlan && this.selectedPlan.currentPlan.productType && !this.paymentRequestDetail.payNowUsedCard) {
        return true;
      }

      return false;
    },

    showCurrentBalance() {
      return this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "selectedPlanConfig.showTransactionBalance", false);
    },

    moneyFormat(data) {
      return `$ ${parseFloat(data).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },

    openRegisterBankTransferPopup() {
      this.registerBankTransferPopup = true;
    },

    closeRegisterBankTransferModal() {
      this.registerBankTransferPopup = false;
      this.register = {};
      this.amPm = "";
      this.errors.clear();
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    async registerBankTransfer() {
      const valid = await this.$validator.validate();

      if (valid) {
        this.$vs.loading();
        const id = this.$route.params.id;
        this.register.paymentTime = `${this.register.paymentTime} ${this.amPm}`;
        this.registerBankTransferPost({ data: this.register, id: id }).then((res) => {
          this.$vs.loading.close();
          this.closeRegisterBankTransferModal();
          this.showMessage(
            "Success",
            "The bank transfer has been successfully registered.",
            "success",
            "icon-check-circle"
          );
        }).catch((ex) => {
          this.$vs.loading.close();
          this.closeRegisterBankTransferModal();
          this.showMessage("Error", ex.message, "danger", "icon-times");
        });
      }
    },

    openDeletePopUp() {
      this.deletePopUp = true;
    },

    openResendPopUp() {
      this.resendPopUp = true;
    },

    cancel() {
      if (this.isRepayEnabled && !this.repayTerms) {
        return false;
      }

      this.cancelPaymentRequest({ type: this.isRepayEnabled ? "repay" : "cancel", repayTerms: this.repayTerms, paymentMethodTerms: this.latestDirectDebitTerms._id });
      this.hideDeletePopup();
    },

    hideDeletePopup() {
      this.deletePopUp = false;
      this.repayTerms = false;
    },

    hideResendPopup() {
      this.resendPopUp = false;
    },

    resend() {
      this.resendButtonDisable = true;
      this.resendButtonText = "Processing";
      this.resendPaymentRequest();
    },

    showPlans() {
      this.payLaterPopUp = true;
    },

    conditionalClass(value = "Pending") {
      let colorClass;
      switch (value) {
        case "Pending":
          colorClass = "yellow";
          break;
        case "Info-required":
          colorClass = "yellow";
          break;
        case "Expired":
          colorClass = "red";
          break;
        case "Completed":
          colorClass = "green";
          break;
        case "Cancelled":
        case "Declined":
          colorClass = "red";
          break;
        case "Active":
          colorClass = "blue";
          break;
        case "Refunded":
          colorClass = "yellow";
          break;
        case "Extended":
          colorClass = "blue";
          break;
        case "Submitted":
          colorClass = "yellow";
          break;
      }
      return colorClass;
    },

    momentExpiryDate(date) {
      return moment(date).format("DD-MMM-YYYY HH:mm");
    },

    closePayLaterPopUp() {
      this.payLaterPopUp = false;
    },

    auto_grow(elem) {
      let element = document.getElementById(elem);
      element.style.height = "5px";
      element.style.height = element.scrollHeight + "px";
    },

    updateStatus() {
      this.statusButtonDisable = true;
      this.updatePaymentRequest();
      this.statusPopUp = false;
      this.statusButtonDisable = false;
    },

    addPopUpClass(enabled) {
      if (enabled) {
        document.body.classList.add("custom-popup", "decline-popup");
      } else {
        document.body.classList.remove("custom-popup", "decline-popup");
      }
    },

    getDirectDebitLegals() {
      this.$vs.loading();
      this.fetchTermsAndConditions().then((response) => {
        const allTerms = response.data.data;
        const privacyTerms = allTerms.privacy;
        const websiteTerms = allTerms.websiteTerms;

        this.latestDirectDebitTerms = allTerms.directDebitTerms;
        this.latestDirectDebitTerms.explicitTerms = this.replaceLegalContent(
          this.latestDirectDebitTerms.explicitTerms,
          {
            fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.latestDirectDebitTerms._id}?type=DIRECT_DEBIT&fullDocument=true" target="_blank">${this.latestDirectDebitTerms.documentName}</a>`,
            privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${privacyTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${privacyTerms.documentName}</a>`,
            generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${websiteTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${websiteTerms.documentName}</a>`,
          }
        );

        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },
  },
  created() {
    VeeValidate.Validator.extend("is_time", {
      getMessage: (field) => `The format must be HH:MM`,
      validate: (value) =>
        new Promise((resolve) => {
          let regex = new RegExp("([0-1][0-9]|2[0-3]):([0-5][0-9])");
          resolve({ valid: value && regex.test(value) });
        }),
    });
    this.checkIfAdminLogin();
  },
  computed: {
    showTopUpButton() {
      return (this.paymentRequestDetail.paymentStatus == "Active" && this.paymentRequestDetail && this.paymentRequestDetail.selectedPlanConfig && this.paymentRequestDetail.selectedPlanConfig.merchantTopUp && this.paymentRequestDetail.selectedPlanConfig.merchantTopUp.isEnabled);
    },

    showDisbursementButton() {
      const allowDisbursementDateOverride = (this.paymentRequestDetail && this.paymentRequestDetail.productCurrentConfig && this.paymentRequestDetail.productCurrentConfig.allowDisbursementDateOverride);
      const disbursedTransaction = this.paymentRequestDetail.transactions.find(item => item.type === "Disbursement" && ["Disbursed", "Processing", "Queued"].includes(item.status));

      return (["Active", "Pending", "Submitted"].includes(this.paymentRequestDetail.paymentStatus) && allowDisbursementDateOverride && !disbursedTransaction);
    },

    showEditOption() {
      return (this.paymentRequestDetail.paymentStatus == "Pending" && !this.isTwoPartyProduct);
    },

    rules() {
      return "requiredAmount";
    },

    selectedPlan() {
      let plan = null;

      if (this.paymentRequestDetail.requestOptions.includes("pay-later")) {
        plan = this.paymentRequestDetail.selectedPaymentPlan ? this.paymentRequestDetail.selectedPaymentPlan : this.paymentRequestDetail.payLaterPlan[0];
      }

      return plan;
    },

    isOneOff() {
      return (this.paymentRequestDetail.requestType === "one-off");
    },

    isRecurring() {
      return (this.paymentRequestDetail.requestType === "recurring");
    },

    hasPaylater() {
      return (this.paymentRequestDetail.requestOptions.includes("pay-later"));
    },

    hasPayNow() {
      return (this.paymentRequestDetail.requestOptions.includes("pay-now"));
    },

    isDisbursed() {
      let isDisbursed = false;

      if (this.paymentRequestDetail.transactions && this.paymentRequestDetail.transactions.find(item => item.type === "Disbursement" && item.status === "Disbursed")) {
        isDisbursed = true;
      }

      return isDisbursed;
    },

    isEarlyPayout() {
      let isEarlyPayout = false;

      if (this.paymentRequestDetail.transactions && this.paymentRequestDetail.transactions.find(item => !item.deleted && (item.type === "Payment") && (item.subType === "Early Payout") && (item.status === "Processing"))) {
        isEarlyPayout = true;
      }

      return isEarlyPayout;
    },

    isTwoPartyProduct() {
      return (this.paymentRequestDetail && this.paymentRequestDetail.selectedPaymentPlan && this.paymentRequestDetail.selectedPaymentPlan.currentPlan && this.paymentRequestDetail.selectedPaymentPlan.currentPlan.productConfigType == "TWO_PARTY");
    },

    showCancelButton() {
      let status = false;

      if (["Info-required", "Pending", "Submitted"].includes(this.paymentRequestDetail.paymentStatus)) {
        status = true;
      } else if (this.isOneOff && this.hasPayNow && this.paymentRequestDetail.paymentStatus === "Expired") {
        status = true;
      } else if (!this.isEarlyPayout && this.paymentRequestDetail.paymentStatus === "Active" && (this.isRecurring || this.isTwoPartyProduct || this.showDisbursementButton)) {
        status = true;
      }

      return status;
    },

    user() {
      return this.$store.state.AppActiveUser;
    },

    merchantId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },

    viewingByFranchisor() {
      return (this.merchantId == this.paymentRequestDetail.franchisorId);
    },

    isRepayEnabled() {
      const enabled = this.isTwoPartyProduct && this.isDisbursed && (this.paymentRequestDetail.paymentStatus === "Active");

      if (enabled) {
        this.getDirectDebitLegals();
      }

      return enabled;
    },

    isAutoDebit() {
      return (this.paymentRequestDetail && this.paymentRequestDetail.selectedPaymentPlan && this.paymentRequestDetail.selectedPaymentPlan.currentPlan && this.paymentRequestDetail.selectedPaymentPlan.currentPlan.autoDebit);
    }
  },
  watch: {
    statusPopUp() {
      this.addPopUpClass(this.statusPopUp);
    },
    deletePopUp(val) {
      if (!val) {
        this.hideDeletePopup();
      }
    }
  }
};
</script>

