<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item ref="termsInformation" :open="true">
        <div slot="header">
          <h3 class="text-left font-normal">Terms and Conditions <span class="mid-blue">*</span></h3>
        </div>

        <div class="flex flex-row custom-quillEditor legals-custom payment-legals" v-if="autoDebit && from !== 'request-form'">
          <div>
            <vs-checkbox class="mt-1" name="directDebitTerm" id="directDebitTerm" data-vv-as="Direct debit terms and conditions" @input="checkForm" v-validate="'required'" v-model="directDebitTerm"></vs-checkbox>
          </div>
          <div>
            <p v-html="latestDirectDebitTerms.explicitTerms"></p>
            <span class="text-danger text-sm" v-show="errors.has('directDebitTerm')">{{ errors.first("directDebitTerm") }}</span>
          </div>
        </div>
        <div class="flex flex-row mt-3 custom-quillEditor legals-custom payment-legals">
          <div>
            <vs-checkbox class="mt-1" name="productTerm" id="productTerm" data-vv-as="Terms and Conditions" @input="checkForm" v-validate="'required'" v-model="productTerm"></vs-checkbox>
          </div>
          <div>
            <p v-html="latestTerms.explicitTerms"></p>
            <span class="text-danger text-sm" v-show="errors.has('productTerm')">{{ errors.first("productTerm") }}</span>
          </div>
        </div>

        <div class="flex flex-row mt-8 ml-8" v-if="showButtons">
          <vs-button flat v-round size="medium" class="mr-3" :disabled="disableAcceptButton" @click="acceptPopUp = true">Accept</vs-button>
          <vs-button flat v-round size="medium" class="only-border-btn" @click="declinePopup = true">Decline</vs-button>
        </div>
      </vs-collapse-item>
    </vs-collapse>

    <!-- pop up after decline request is clicked -->
    <vs-popup class="holamundo" title="Decline request" :active.sync="declinePopup">
      <p>Are you sure you want to decline this payment request?</p>
      <div class="flex-end mt-5 flex-end">
        <vs-button @click="declinePaymentRequest" class="mr-5">Confirm</vs-button>
        <vs-button @click="declinePopup = false">Cancel</vs-button>
      </div>
    </vs-popup>

    <!-- pop up after accept request is clicked -->
    <vs-popup class="holamundo" title="Accept request" :active.sync="acceptPopUp">
      <p>Are you sure you want to accept this payment request?</p>
      <div class="flex-end mt-5 flex-end">
        <vs-button @click="accept" class="mr-5">Confirm</vs-button>
        <vs-button @click="acceptPopUp = false">Cancel</vs-button>
      </div>
    </vs-popup>
  </vx-card>
</template>
<script>
  import { mapActions } from "vuex";
  export default {
    props: ["autoDebit", "from", "fullTerms","paymentRequestId", "showButtons", "termsDetail"],
    data() {
      return {
        latestTerms: "",
        appUrl: process.env.VUE_APP_ROOT_API,
        otherLegals: {},
        productTerm: null,
        directDebitTerm: null,
        latestDirectDebitTerms: "",
        allTerms: {},
        declinePopup: false,
        acceptPopUp: false,
      }
    },
    methods: {
      ...mapActions("legals", ["fetchTermsAndConditions"]),
      ...mapActions("paymentRequest", ["acceptFranchisorPaymentRequest"]),

      handleTerms(terms) {
        this.allTerms = terms;
        const privacyTerms = this.allTerms.privacy;
        const websiteTerms = this.allTerms.websiteTerms;
        const findCustomerLegal = this.termsDetail.filter((item) => item.user == "CUSTOMER");

        this.latestTerms = findCustomerLegal[findCustomerLegal.length - 1];
        this.latestTerms.explicitTerms = this.replaceLegalContent(
          this.latestTerms.explicitTerms,
          {
            fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.latestTerms._id}?type=PAY_LATER_LEGAL&fullDocument=true" target="_blank">${this.latestTerms.documentName}</a>`,
            privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${privacyTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${privacyTerms.documentName}</a>`,
            generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${websiteTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${websiteTerms.documentName}</a>`,
          }
        );

        this.latestDirectDebitTerms = this.allTerms.directDebitTerms;
        this.latestDirectDebitTerms.explicitTerms = this.replaceLegalContent(
          this.latestDirectDebitTerms.explicitTerms,
          {
            fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.latestDirectDebitTerms._id}?type=DIRECT_DEBIT&fullDocument=true" target="_blank">${this.latestDirectDebitTerms.documentName}</a>`,
            privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${privacyTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${privacyTerms.documentName}</a>`,
            generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${websiteTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${websiteTerms.documentName}</a>`,
          }
        );
      },

      getLegals() {
        this.$vs.loading();
        this.fetchTermsAndConditions().then((response) => {
          this.handleTerms(response.data.data);
          this.$vs.loading.close();
        }).catch((ex) => {
          this.$vs.loading.close();
        });
      },

      validateChildForm() {
        return this.$validator.validateAll();
      },

      checkForm() {
        if (!this.showButtons) {
          this.$emit("checkForm");
        }
      },

      async accept() {
        if (this.disableAcceptButton) {
          return;
        }

        const payload = {
          terms: this.latestTerms._id,
          privacy: this.allTerms.privacy._id,
        };

        if (this.autoDebit) {
          payload.paymentMethodTerms = this.latestDirectDebitTerms._id;
        }

        const obj = {
          obj: payload,
          id: this.paymentRequestId,
        };
        this.acceptPopUp = false;
        this.$vs.loading();

        await this.acceptFranchisorPaymentRequest(obj).then((result) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "success",
            title: "Payment request",
            text: result.data.message,
          })
          this.$emit("update");
        }).catch(ex => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Payment request",
            text: ex.response.data.message,
          });
        });
      },

      declinePaymentRequest() {
        this.$emit("declineRequest");
      }
    },

    mounted() {
      if (this.from !== "request-form") {
        this.getLegals();
      } else {
        this.handleTerms(this.fullTerms);
      }
    },

    watch: {
      productTerm(val) {
        if (val) {
          if (!this.showButtons) {
            const data = { val: val, terms: this.latestTerms._id, privacy: this.allTerms.privacy._id };
            this.$emit("on", data);
          }

          return;
        }
        this.productTerm = null;
      },

      directDebitTerm(val) {
        if (val) {
          if (!this.showButtons) {
            const data = { val: val, directDebit: true, termsId: this.latestDirectDebitTerms._id, terms: this.latestTerms._id, privacy: this.allTerms.privacy._id };
            this.$emit("on", data);
          }
          return;
        }
        this.directDebitTerm = null;
      }
    },

    computed: {
      disableAcceptButton() {
        return !this.productTerm || (this.autoDebit ? !this.directDebitTerm : false);
      }
    }
  }
</script>

